async function get(url: string, headers?: any) {
  const response = await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...headers
    }
  });

  return await response.json();
}

async function post(url: string, payload: any, headers?: any) {
  const response = await fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...headers
    },
    body: JSON.stringify(payload)
  });

  return await response.json();
}

async function download(url: string, payload: any, headers?: any) {
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...headers
    },
    body: JSON.stringify(payload)
  });

  return await response;
}

export default {
  get,
  post,
  download
}