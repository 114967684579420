let tts: any;

async function init(src: string) {
  return new Promise((resolve, reject) => {
    let audio = new Audio(src);
    audio.play();

    audio.addEventListener('playing', () => resolve(audio));
    audio.addEventListener('error', reject);
  });
}

async function play(text: string, token: string, brazilian = false) {
  tts = await init(`${process.env.REACT_APP_API_URL}/api/v1/tts?text=${text}&authorization=${token}&brazilian=${brazilian}`);
}

function pause() {
  if (tts && tts.pause) tts.pause();
}

export default {
  play,
  pause
};
