import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import './style.css';

import http from '../../libs/http';
import alert from '../../libs/alert';

function Conf({ user, setUser }: any) {
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    init(user);
  }, []);

  async function init(user: any) {
    setName(user.name);
    setEmail(user.email);
  }

  async function handleSignUpFormSubmit(event: any) {
    event.preventDefault();
    setLoading(true);

    const url = `${process.env.REACT_APP_API_URL}/api/v1/user/update`;
    const headers = { Authorization: user.token };
    let payload: any = { name, email };

    if (password && password.length) {
      payload.password = password;
    }

    const response = await http.post(url, payload, headers);

    if (response.error) {
      alert.error(response.error);
      setLoading(false);
      return;
    }

    setUser({ ...user, name, email });
    setLoading(false);
    localStorage.setItem('user', JSON.stringify({ ...user, name, email }));
    alert.success('Sucesso! Seus dados foram atualizados!')
  }

  function plan() {
    navigate('/planos');
  }

  return (
    <div>
      <form onSubmit={handleSignUpFormSubmit}>
        <div>
          <input
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="App-input mb-10"
            placeholder='Nome'
            required
          />
        </div>
        <div>
          <input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="App-input mb-10"
            placeholder='E-mail'
            type='email'
            required
          />
        </div>
        <div>
          <input
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="App-input"
            placeholder='Nova Senha'
            autoComplete='new-password'
            type='password'
          />
        </div>
        <button
          className="App-button mt-20"
        >
          { loading ? 'Salvando...' : 'Salvar' }
        </button>
      </form>
      {/* <button
        className="App-button-golden mt-10"
        onClick={plan}
      >
        Assinatura
      </button> */}
    </div>
  );
}

export default Conf;
