import { toast } from 'react-toastify';

function success(message: string) {
  const options: any = {
    position: 'top-right',
    autoClose: 7000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'dark'
  };

  return toast(message, options);
};

function warn(message: string) {
  const options: any = {
    position: 'top-right',
    autoClose: 7000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'dark'
  };

  return toast.warn(message, options);
};

function error(message: string) {
  const options: any = {
    position: 'top-right',
    autoClose: 7000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'dark'
  };

  return toast.error(message, options);
};

export default {
  success,
  warn,
  error
};