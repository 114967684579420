import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './style.css';

import http from '../../libs/http';
import alert from '../../libs/alert';

function Journey({ user }: any) {
  const navigate = useNavigate();

  const [page, setPage] = useState(0);
  const [campaign, setCampaign] = useState(0);
  const [video, setVideo] = useState({ id: 0, title: '', url: '', desc: '', check: false });
  const [videos, setVideos] = useState([]);

  async function getContent(id: any = null) {
    const url = `${process.env.REACT_APP_API_URL}/api/v1/class-room/content`;
    const headers = { Authorization: user.token };

    const payload: any = {};
    if (id) payload.id = id;

    return await http.post(url, payload, headers);
  }

  async function checkContent(id: number) {
    setVideo({ ...video, check: !video.check });

    const url = `${process.env.REACT_APP_API_URL}/api/v1/class-room/check`;
    const headers = { Authorization: user.token };
    const payload: any = { id };

    await http.post(url, payload, headers);
    await init();
  }

  async function init() {
    const result = await getContent();
    setVideos(result);
  }

  function showRoad(page = 1, campaign = 1) {
    setPage(page)
    setCampaign(campaign);
  }

  async function watch(video: any) {
    const response = await getContent(video.id);

    if (response.error === 'TeacherFlix não liberado para esse plano!') {
      return alert.success(
        'Quer ter acesso as nossas aulas? Assine nosso plano Platinum e tenha acesso ilimitado!'
      );
    }

    setVideo(response);
    setPage(2);
  }
  
  function back() {
    if (page === 0) {
      return navigate(-1);
    }

    setPage(page - 1);
  }

  useEffect(() => {
    init();
  }, []);

  return (
    <div className="App-journey">
      { page === 0 &&
        <div>
          <h1 className="mb-15">Reforçe seu conhecimento</h1>
          <button
            className="App-button mt-10"
            onClick={() => showRoad(1, 1)}
          >
            Básico
          </button>
          <button
            className="App-button mt-10"
            onClick={() => showRoad(1, 2)}
          >
            Avançado
          </button>
        </div>
      }
      { page === 1 &&
        <div>
          { videos && videos.length > 0 && videos.map((video: any, index) => video.campaign == campaign ?
              <div key={index}>
                { video.module &&
                  <h1>{ video.module }</h1>
                }
                <button
                  className={`${video.check ? 'App-button-hover' : 'App-button'} mt-10`}
                  onClick={() => watch(video)}
                >
                  { video.title }
                </button>
                <div className="App-line mt-10"></div>
              </div>
              :
              null
            )
          }
          <button className="App-button mt-10">
            Em breve...
          </button>
        </div>
      }
      { page === 2 &&
        <div className="App-teacherflix-video">
          <h1>{video.title} </h1>
          <p className="mb-30" dangerouslySetInnerHTML={{__html: video.desc}}/>
          <iframe
            frameBorder={0}
            src={video.url}
          >
          </iframe>
          <button
            className={`${video.check ? 'App-button-hover' : 'App-button'} mt-20`}
            onClick={() => checkContent(video.id)}
          >
            Concluír Aula
          </button>
        </div>
      }
      <button
        className="App-button-no-border mt-10"
        onClick={back}
      >
        Voltar
      </button>
    </div>
  );
}

export default Journey;
