import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './style.css';

import http from '../../libs/http';
import alert from '../../libs/alert';

function Login({ setUser }: any) {
  const [isSignUp, setIsSignUp] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const [isRecovery, setIsRecovery] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  async function handleSignUpFormSubmit(event: any) {
    event.preventDefault();
    setLoading(true);

    const url = `${process.env.REACT_APP_API_URL}/api/v1/user/create`;
    const payload = { name, email, password };
    const response = await http.post(url, payload);

    if (response.error) {
      alert.error(response.error);
      setLoading(false);
      return;
    }

    setUser(response);
    setLoading(false);
    localStorage.setItem('user', JSON.stringify(response));
    navigate('/');
  }

  async function isFounder(user: any): Promise<boolean> {
    const url = `${process.env.REACT_APP_API_URL}/api/v1/user/medals`;
    const headers = { Authorization: user.token };
    const response = await http.get(url, headers);

    if (!response || !response.length) {
      return false;
    };

    if (response.find((el: any) => el.medalId == 1)) {
      return true;
    }

    return false;
  }

  async function handleLoginFormSubmit(event: any) {
    event.preventDefault();
    setLoading(true);

    const url = `${process.env.REACT_APP_API_URL}/api/v1/user/authenticate`;
    const payload = { email, password };
    const response = await http.post(url, payload);

    if (response.error) {
      alert.warn(response.error);
      setLoading(false);
      return;
    }

    response.isFounder = await isFounder(response);

    setUser(response);
    setLoading(false);
    localStorage.setItem('user', JSON.stringify(response));
    navigate('/');
  }

  async function handleRecoveryFormSubmit(event: any) {
    event.preventDefault();
    setLoading(true);

    const url = `${process.env.REACT_APP_API_URL}/api/v1/user/recovery`;
    const payload = { email };
    const { error, message } = await http.post(url, payload);

    if (error) {
      alert.error(error);
      setLoading(false);
      return;
    }

    alert.success(message);
    setLoading(false);
    resetStates();
  }

  function resetStates() {
    setIsSignUp(false);
    setIsLogin(false);
    setIsRecovery(false);
    setName('');
    setEmail('');
    setPassword('');
  }

  return (
    <>
      { isSignUp && !isRecovery &&
        <div>
          <form onSubmit={handleSignUpFormSubmit}>
            <div>
              <input
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="App-input mb-10"
                placeholder='Nome'
                required
              />
            </div>
            <div>
              <input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="App-input mb-10"
                placeholder='E-mail'
                type='email'
                required
              />
            </div>
            <div>
              <input
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="App-input"
                placeholder='Senha'
                type='password'
              />
            </div>
            <button
              className="App-button mt-20"
            >
              { loading ? 'Inscrevendo-se...' : 'Inscreva-se' }
            </button>
          </form>
          <button
            className="App-button-no-border mt-10"
            onClick={resetStates}
          >
            Voltar
          </button>
        </div>
      }

      { isLogin && !isRecovery &&
        <div>
          <form onSubmit={handleLoginFormSubmit}>
            <div>
              <input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="App-input mb-10"
                placeholder='E-mail'
                required
              />
            </div>
            <div>
              <input
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="App-input"
                placeholder='Senha'
                type='password'
                required
              />
            </div>
            <button
              className="App-button mt-20"
            >
              { loading ? 'Entrando...' : 'Entrar' }
            </button>
          </form>
          <button
            className="App-button-no-border mt-10"
            onClick={resetStates}
          >
            Voltar
          </button>
        </div>
      }
      { !isSignUp && !isLogin && !isRecovery &&
        <>
          <button
            className="App-button"
            onClick={() => setIsLogin(true)}
          >
            Entre e bora conversar
          </button>
          <button
            className="App-button-no-border mt-10"
            onClick={() => setIsSignUp(true)}
          >
            Inscreva-se e junte-se a nós!
          </button>
        </>
      }
      { !isSignUp && !isLogin && isRecovery && 
        <div>
          <form onSubmit={handleRecoveryFormSubmit}>
            <div>
              <input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="App-input"
                placeholder='E-mail'
                required
              />
            </div>
            <button
              className="App-button mt-20"
            >
              { loading ? 'Recuperando...' : 'Recuperar' }
            </button>
          </form>
          <button
            className="App-button-no-border mt-10"
            onClick={resetStates}
          >
            Voltar
          </button>
        </div>
      }
      <div 
        className="App-contact"
        onClick={() => {
          setIsSignUp(false); setIsLogin(false); setIsRecovery(true);
        }}
      >
        Já conversou comigo e perdeu sua conta? Clique aqui e recupere!
      </div>
    </>
  );
}

export default Login;
