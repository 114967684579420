import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import './style.css';

import tts from '../../libs/tts';

function Pronouncer({ user }: any) {
  const navigate = useNavigate();

  const [text, setText] = useState('');
  const [loading, setLoading] = useState(false);
 
  async function speak(text: string) {
    await tts.play(text, user.token);
  }

  async function handleSignUpFormSubmit(event: any) {
    event.preventDefault();
    setLoading(true);
    await speak(text);
    setLoading(false);
  }

  function back() {
    navigate(-1);
  }

  return (
    <div>
      <form onSubmit={handleSignUpFormSubmit}>
        <div>
          <input
            value={text}
            onChange={(e) => setText(e.target.value)}
            className="App-input mb-10"
            placeholder='Digite aqui....'
            maxLength={30}
            required
          />
        </div>
        <button
          className="App-button mt-20"
        >
          { loading ? 'Carregando...' : 'Pronunciar' }
        </button>
      </form>
      <button
        className="App-button-no-border mt-10"
        onClick={back}
      >
        Voltar
      </button>
    </div>
  );
}

export default Pronouncer;
