const languages = {
  ptBR: 'pt-BR',
  enUS: 'en-US'
};

let speaker: any;
let bufferSound: any;
  
function isBrazilianMode(lang: string) {
  return lang === languages.ptBR;
}

function getSynthVoices(): Promise<any[]> {
  return new Promise((resolve: any, reject: any) =>{
    let synth = window.speechSynthesis;
    let id: any;

    id = setInterval(() => {
      if (!synth.getVoices().length) return;
      resolve(synth.getVoices());
      clearInterval(id);
    }, 10);
  });
}

async function getVoice(lang: string) {
  const voices: any[] = await getSynthVoices();

  if (!isBrazilianMode(lang)) {
    return voices.find(({ name }) => name == 'Samantha');
  }

  return voices.find(({ name }) => name == 'Google português do Brasil' || 'Sandy (Portuguese (Brazil))');
}

async function load(lang = languages.enUS) {
  let rate = 0.9;
  let pitch = 1.2;

  if (isBrazilianMode(lang)) {
    rate = 1.2;
  }

  speaker = new SpeechSynthesisUtterance();
  speaker.voice = await getVoice(lang);
  speaker.volume = 1;
  speaker.voiceURI = 'native';
  speaker.rate = rate;
  speaker.pitch = pitch;
  speaker.lang = lang;

  // For IOS. Read more here https://stackoverflow.com/questions/32193704/js-speech-synthesis-issue-on-ios
  speak('');

  return speaker;
}

async function speak(text: string) {
  speaker.text = text;
  stop();
  window.speechSynthesis.speak(speaker);
}

function stop() {
  window.speechSynthesis.cancel();

  if (bufferSound) {
    bufferSound.stop();
  }
}

export default {
  languages,
  load,
  speak,
  stop
};
