import React from 'react';
import { useNavigate } from 'react-router-dom';

import './style.css';

function Plan({ user }: any) {
  const navigate = useNavigate();

  function back() {
    navigate(-1);
  }

  return (
    <div className='App-plan-body'>
      <p>
        { user.planId === '1' && 'Que tal dar uma olhadinha em nossos planos e benefícios? Veja à baixo!' } 
        { user.planId === '2' && 'Quer evoluir ainda mais? Assine nosso plano Platinum logo à baixo!' } 
        { user.planId === '3' && 'Parabéns, você já possuí o nosso melhor plano, o Platinum!' } 
      </p>
      <div className='App-plan-section'>
        <b>PLATINUM { user.planId === '3' && '(ATUAL)'}</b><br/>
          <p className="App-plan-p">
            Vamos conversar em Inglês pelo tempo que você quiser, todos os dias! Você tem total controle sobre como e quando deseja praticar. E tem mais! Além disso, vou providenciar uma aula de conversação AO VIVO com um professor humano incrível para todos os meus usuários, uma vez por mês. E não para por aí! Você também será incluído em nosso grupo exclusivo de dicas semanais, onde um especialista humano estará disponível para ajudar com detalhes específicos que só humanos entendem. Ah, e não posso esquecer de mencionar nossas vídeo aulas, que vão te levar do zero à fluência, além das aulas de aprimoramento para aqueles que já são fluentes. Tudo isso por apenas R$49,90/mês. Garanta já o seu lugar nessa incrível comunidade de aprendizado!
          </p>
        <br/>
        <button
          className="App-button-golden"
          onClick={() => window.open('https://pay.kiwify.com.br/yrSXlLQ')}
          disabled={user.planId === '3'}
        >
          Adquirir por R$ 49,90/mês
        </button>
      </div>
      <div className='App-plan-section'>
        <b>GOLDEN { user.planId === '2' && '(ATUAL)'}</b><br/>
          <p className="App-plan-p">
            Vamos conversar em Inglês por até 45 min todos os dias! Você decide como dividir esse tempo ao longo do seu dia. E tem mais! Vou te colocar em um grupo exclusivo de dicas semanais, onde um especialista humano vai te ajudar com detalhes específicos que só humanos entendem, hehe. Tudo isso por apenas R$29,90/mês. Garanta já o seu!
          </p>
        <br/>
        <button
          className="App-button-golden"
          onClick={() => window.open('https://pay.kiwify.com.br/LzJ6RRd')}
          disabled={user.planId === '2' || user.planId === '3'}
        >
          Adquirir por R$ 29,90/mês
        </button>
      </div>
      <div className='App-plan-section'>
        <b>STARTER { user.planId === '1' && '(ATUAL)'}</b><br/>
          <p className="App-plan-p">
            No plano STARTER, você tem acesso GRATUITO a mim por até 7 minutos por dia. Se quiser aprofundar nossas conversas, receber feedbacks, participar de comunidades e muito mais, dê uma olhada nos planos Golden e Platina. Se preferir continuar com o STARTER, é só se cadastrar rapidinho e já podemos começar a conversar. Vamos lá!
          </p>
      </div>
      <button
        className="App-button-no-border mb-60"
        onClick={back}
      >
        Voltar
      </button>
    </div>
  );
}

export default Plan;
