import { useEffect, useState } from 'react';

import { Route, Routes, BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import SideBar from './sidebar';
import Plan from './plan';
import Login from './login'
import Page from './page';
import Home from './home';
import Conversation from './conversation';
import Chat from './chat';
import Conf from './conf';
import Pronouncer from './pronouncer';
import Journey from './journey';
import './style.css';

import http from '../libs/http';
import alert from '../libs/alert';

const DEFAULT_USER_STATE = { token: null, planId: null };

function App() {
  const [user, setUser] = useState(DEFAULT_USER_STATE);
  const [loading, setLoading] = useState(true);

  async function load() {
    const storage: any = localStorage.getItem('user');
    if (!storage) return;
    
    let payload = JSON.parse(storage);
    if (!payload || !payload.token || !payload.email) return;
    payload = await isFounder(payload);

    setUser(payload);
  }

  async function isFounder(user: any): Promise<any> {
    const url = `${process.env.REACT_APP_API_URL}/api/v1/user/medals`;
    const headers = { Authorization: user.token };
    const response = await http.get(url, headers);

    if (response.error === 'Token não autorizado!') {
      alert.warn('Ops! Sessão expirada, logue novamente.');
      return { token: null };
    }

    if (!response || !response.length) {
      return user;
    };

    if (response.find((el: any) => el.medalId == 1)) {
      return {
        ...user,
        isFounder: true
      };
    }

    return user;
  }
  
  useEffect(() => {
    load().then(() => setLoading(false));
  }, []);

  function Router() {
    if (loading) return <div className="loader"><span></span></div>;

    if (!user.token) {
      return (
        <Routes>
          <Route Component={() => <Login setUser={setUser}/>} path="/" />
          <Route Component={() => <Login setUser={setUser}/>} path="/login" />
        </Routes>
      )
    }

    const handler = (Component: any) => <Component user={user} setUser={setUser}/>;

    return (
      <>
        <Routes>
          <Route Component={() => handler(Home)} path="/" />
          <Route Component={() => handler(Journey)} path="/jornada" />
          <Route Component={() => handler(Conversation)} path="/conversacao" />
          <Route Component={() => handler(Plan)} path="/planos" />
          <Route Component={() => handler(Chat)} path="/chat" />
          <Route Component={() => handler(Pronouncer)} path="/pronunciador" />
          <Route Component={() => handler(Conf)} path="/perfil" />
        </Routes>
        <SideBar setUser={setUser}/>
      </>
    )
  }

  return (
    <BrowserRouter>
      <div className="App">
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <Router/>
      </div>
    </BrowserRouter>
  );
}

export default App;
