import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import logo from '../../assets/logo.png';
import founder from '../../assets/medal/founder.png';
import './style.css';

function Home({ user }: any) {
  const { pathname } = useLocation();

  if (pathname != '/') {
    return <></>
  }

  return (
    <>
      <div>
        <img src={(user && user.isFounder) ? founder : logo} className="App-atari mb-10" alt="logo" />
      </div>
      <Link to="/conversacao">
        <button className="App-button mt-10">
          Fale com o ATARI
        </button>
      </Link>
      <Link to="/jornada">
        <button className="App-button mt-10">
          Reforçe seu conhecimento
        </button>
      </Link>
      { user.planId != 1 &&
        <button className="App-button mt-10"
          onClick={() => window.open('https://chat.whatsapp.com/I106BeU2IQ15ihxfwOQy0Q')}
        >
          Comunidade de Dicas
        </button>
      }
    </>
  );
}

export default Home;
