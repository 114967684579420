import { Link, useLocation, useNavigate } from 'react-router-dom';

function SideBar({ setUser }: any) {
  const on = '#7932b6';
  const off = '#fff';

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isEnabled = (uri: any) => uri.includes(pathname);

  function resetSession() {
    const payload = { token: null };
    setUser(payload);
    localStorage.setItem('user', JSON.stringify(payload));
  }

  function logoff() {
    if (!window.confirm('Tem certeza que deseja sair do ATARI?')) return;
    resetSession();
    navigate('/');
  }

  return (
    <div className="App-side-bar">
      <Link to="/">
        <i className="fa-solid fa-house"
          style={{ color: isEnabled(['/']) ? on : off }}
        ></i>
      </Link>
      <Link to="/perfil">
        <i className="fa-solid fa-user-gear"
          style={{ color: isEnabled(['/perfil', '/planos']) ? on : off }}
        ></i>
      </Link>
      <i 
        className="fa-solid fa-right-from-bracket"
        style={{ color: off }}
        onClick={logoff}
      ></i>
    </div>
  )
}

export default SideBar;
