import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/logo.png';
import './style.css';

function Conversation() {
  const navigate = useNavigate();
  const [showInterviews, setShowInterviews] = useState(false);

  const interviews = [
    {
      name: 'Grandes Animais (Veterinaria)',
      prompt: 'Play an interactive interview with me, you are ATARI, an interviewer and you make one question at a time for me and I answer you back. I am the candidate for a position as a large animals vet to work with it in a big company. Intrtoduce yourself ad make the first question for me.'
    },
    {
      name: 'Pequenos Animais (Veterinaria)',
      prompt: 'Play an interactive interview with me, you are ATARI, an interviewer and you make one question at a time for me and I answer you back. I am the candidate for a position as a small animals vet to work with it in a big company. Intrtoduce yourself ad make the first question for me.'
    },
    {
      name: 'Estágio (Veterinaria)',
      prompt: 'Play an interactive interview with me, you are ATARI, an interviewer and you make one question at a time for me and I answer you back. I am the candidate for a position as a trainee vet to work with it in a big company. Intrtoduce yourself ad make the first question for me.'
    },
    {
      name: 'Desenvolvedor (TI)',
      prompt: 'Play an interactive interview with me, you are ATARI, an interviewer and you make one question at a time for me and I answer you back. I am the candidate for a position as a developer to work with it in a big company. Intrtoduce yourself ad make the first question for me.'
    },
    {
      name: 'Analista de dados (TI)',
      prompt: 'Play an interactive interview with me, you are ATARI, an interviewer and you make one question at a time for me and I answer you back. I am the candidate for a position as a data analyst to work with it in a big company. Intrtoduce yourself ad make the first question for me.'
    },
    {
      name: 'Engenheiro de Software (TI)',
      prompt: 'Play an interactive interview with me, you are ATARI, an interviewer and you make one question at a time for me and I answer you back. I am the candidate for a position as a software engineer to work with it in a big company. Intrtoduce yourself ad make the first question for me.'
    },
    {
      name: 'Marketing (ADM)',
      prompt: 'Play an interactive interview with me, you are ATARI, an interviewer and you make one question at a time for me and I answer you back. I am the candidate for a position as a Marketing person to work with it in a big company. Intrtoduce yourself ad make the first question for me.'
    },
    {
      name: 'Gerente (ADM)',
      prompt: 'Play an interactive interview with me, you are ATARI, an interviewer and you make one question at a time for me and I answer you back. I am the candidate for a position as a manager to work with it in a big company. Intrtoduce yourself ad make the first question for me.'
    },
    {
      name: 'Assistente Administrativo (ADM)',
      prompt: 'Play an interactive interview with me, you are ATARI, an interviewer and you make one question at a time for me and I answer you back. I am the candidate for a position as an administrative assistant to work with it in a big company. Intrtoduce yourself ad make the first question for me.'
    },
  ];

  function chat(payload = {}) {
    navigate('/chat', { state: payload });
  }

  function chatBrazilianMode() {
    navigate('/chat', { state: { brazilianMode: true } });
  }

  function pronouncer() {
    navigate('/pronunciador');
  }

  return (
    <>
      <button
        className="App-button"
        onClick={() => chat()}
      >
        Bora conversar em Inglês
      </button>
      <button
        className={`${showInterviews ? 'App-button-hover' : 'App-button'} mt-10`}
        onClick={() => setShowInterviews(!showInterviews)}
      >
        Prepare-se para entrevistas
      </button>
      { showInterviews && 
        <>
          { interviews.map(({ name, prompt }, index) =>
            <button
              key={index}
              className={
                `App-button-no-border ${index == interviews.length - 1 ? 'mb-20' : ''} ${index == 0 ? 'mt-10' : ''}`
              }
              onClick={() => chat({ prompt })}
            >
              { name }
            </button>
          )}
        </>
      }
      <button
        className="App-button mt-10"
        onClick={chatBrazilianMode}
      >
        Modo Brasileiro
      </button>
      <button
        className="App-button mt-10"
        onClick={pronouncer}
      >
        Pronunciador
      </button>
      <button
        className="App-button-no-border mt-10"
        onClick={() => navigate(-1)}
      >
        Voltar
      </button>
    </>
  );
}

export default Conversation;
