let lib: any;
let listening: boolean = false;
let text: string = '';
let isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
let isIphone = /iPad|iPhone|iPod/.test(navigator.userAgent);
let interimMode = isSafari && !isIphone;

function load(language: string) {
  const { SpeechRecognition, webkitSpeechRecognition, mozSpeechRecognition, msSpeechRecognition }: any = window;
  lib = new (SpeechRecognition || webkitSpeechRecognition || mozSpeechRecognition || msSpeechRecognition)();
  lib.interimResults = interimMode;
  lib.maxAlternatives = 5;
  lib.lang = language;
}

function rec(call?: any) {
  function onresult({ results }: any) {
    text = interimMode ? results[results.length - 1][0].transcript : `${text} ${results[results.length - 1][0].transcript}`;
    if (!call) return;
    call(text)
  }

  function onend() {
    if (listening) lib.start();
  }

  function onerror({ error }: any) {
    console.log(error);
  }

  listening = true;
  lib.start();
  lib.onresult = onresult;
  lib.onend = onend;
  lib.onerror = onerror;
}

function stop() {
  listening = false;
  text = '';

  if (!lib) return;
  lib.stop();
  lib.abort();
}

export default {
  load,
  rec,
  stop
};