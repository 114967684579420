import { useState, useEffect } from 'react';
import { useNavigate, useLocation  } from 'react-router-dom';
import brazil from '../../assets/brazil.png';
import './style.css';

import http from '../../libs/http';
import recognition from '../../libs/recognition';
import voice from '../../libs/voice';
import tts from '../../libs/tts';
import alert from '../../libs/alert';

function Chat({ user, setUser }: any) {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [hasStarted, setHasStarted] = useState(false);
  const [listening, setListening] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [showTranscript, setShowTranscript] = useState(true);
  const [transcript, setTranscript] = useState('');
  const [answerToTranscript, setAnswerToTranscript] = useState('');
  const [messages, setMessages] = useState([]);
  const [language, setLanguage] = useState(voice.languages.enUS);

  function setupRecognition(lang = language) {
    recognition.load(lang);
  }

  function changeStateAfterStartRec() {
    setListening(true);
  }

  function changeStateAfterStopRec() {
    setListening(false);
    setTranscript('');
  }

  function cancelRec() {
    recognition.stop();
    changeStateAfterStopRec();
    setFetching(false);
  }

  async function rec() {
    try {
      setFetching(true);
      if (!listening) {
        if (!transcript.length) setTranscript(' ');
        recognition.rec((text: string) => setTranscript(text));
        return changeStateAfterStartRec();
      }
  
      recognition.stop();
      setListening(false);
  
      const answer = await send(transcript);

      if (!answer) {
        setFetching(false);
        changeStateAfterStopRec();
        return;
      }

      await speak(answer);
      setFetching(false)
      setAnswerToTranscript(answer);
      changeStateAfterStopRec();

      return;
    } catch (error: any) {
      console.log(error.message);
      exit()
    }
  }

  function setupVoice(lang = language) {
    voice.load(lang);
  }

  async function setupMessages() {
    if (!user.name) {
      return;
    }

    setFetching(true);

    await resetStartPlanUsage();

    let data: any = messages;
    let role: string = 'system';
    let content: string = `You are a english teacher and you will answer questions in english with your student ${user.name}, you don't need speak that you are a IA, but that your are ATARI, the virtual assistant. Now welcome you student.`;

    if (isBrazilianMode()) content = `You are a english teacher and you will answer questions in portuguese with your student ${user.name}, you don't need speak that you are a IA, but that your are ATARI, the virtual assistant. Now welcome you student.`;

    let custom = getPromptFromRoute();
    if (custom) content = custom;

    data.push({ role, content });

    const payload = await sendToChat(data);

    if (!payload) {
      setFetching(false);
      return;
    }

    data.push(payload);
    setMessages(data)

    let text = `Hey ${user.name}, how are you doing? I'm ATARI and I'm here to talk to you and help you to practice your English skills. What would you like to talk about?`;
    if (isBrazilianMode() || getPromptFromRoute()) text = payload.content;

    await speak(text);
    setAnswerToTranscript(`${text}`.replace(/"/gi, ''));
    setFetching(false);
  }

  async function resetStartPlanUsage() {
    try {
      const url = `${process.env.REACT_APP_API_URL}/api/v1/user/reset-usage`;
      const headers = { Authorization: user.token };
      await http.post(url, {}, headers);
    } catch (err: any) {
      console.log(err.message);
    }
  }

  async function send(text: string) {
    let data: any = messages;

    data.push({
      role: "user",
      content: text
    });

    const content = await sendToChat(messages);

    if (!content) {
      return;
    }

    data.push(content);
    setMessages(data)
    return content.content;
  }

  async function postToChat(messages: any) {
    const url = `${process.env.REACT_APP_API_URL}/api/v1/chat`;
    const payload = { messages };
    const headers = { Authorization: user.token };

    return await http.post(url, payload, headers);
  }

  async function sendToChat(messages: any) {
    const { error, choices } = await postToChat(messages);

    if (error === 'Token não autorizado!') {
      alert.warn('Ops! Sessão expirada, logue novamente.');
      resetSession();
      return;
    }

    if (error === 'Plano expirado!') {
      alert.warn('Adorei nossa conversa! Que tal explorar mais com os planos Golden e Platinum? Você pode continuar a conversar ainda hoje ou, se preferir, pode me encontrar novamente amanhã. Estarei aqui esperando por você!');
      exit();
      return;
    }

    if (error) {
      alert.error('Ops! Algo inesperado aconteceu, tente novamente!');
      console.log(error);
      cancelRec();
      voice.stop();
      tts.pause();
      return;
    }

    return choices[0].message;
  }

  function resetSession() {
    const payload = { token: null };
    setUser(payload);
    localStorage.setItem('user', JSON.stringify(payload));
  }

  function isBrazilianMode() {
    return state && state.brazilianMode;
  }

  function getPromptFromRoute() {
    if (!state || !state.prompt) {
      return null;
    }

    return state.prompt;
  }

  async function speak(text: string) {
    await tts.play(text, user.token, isBrazilianMode());
  }

  async function start() {
    let lang = isBrazilianMode() ? voice.languages.ptBR : voice.languages.enUS;
    setHasStarted(true);
    setupRecognition(lang);
    setupVoice(lang);
    setLanguage(lang);
    setupMessages();
  }

  async function feedback() {
    setFetching(true);

    const answer = await send('Give me a feedback of our conversation in english.');
    changeStateAfterStopRec();

    if (!answer) return;

    await speak(answer);

    setFetching(false);
    setAnswerToTranscript(answer);
  }

  function exit() {
    cancelRec();
    voice.stop();
    tts.pause();

    navigate(-1);
  }

  useEffect(() => {
    return () => {
      voice.stop();
      tts.pause();
    }
  }, []);

  return (
    <>
      { isBrazilianMode() &&
        <img
          src={brazil}
          className="App-brazil"
          alt="brazil"
        />
      }
      { answerToTranscript && showTranscript &&
        <div className="App-section">
          <p><b>ATARI:</b> { answerToTranscript }</p>
          { listening && transcript && <p><b>Você:</b> { transcript }...</p> }
        </div>
      }
      { !hasStarted && 
        <button
          className="App-button"
          onClick={start}
        >
          Iniciar
        </button>
      }
      { hasStarted && 
        <button
          className="App-button"
          onClick={rec}
        >
          { listening ? 'Enviar' : fetching ? 'Carregando...' : 'Clique para Falar' }
        </button>
      }
      { hasStarted && answerToTranscript && !listening && !fetching &&
        <button
          className="App-button-no-border mt-10"
          onClick={() => setShowTranscript(!showTranscript)}
          style={{ marginBottom: -10 }}
        >
          { showTranscript ? 'Ocultar Legenda' : 'Mostrar Legenda' }
        </button>
      }
      { listening &&
        <button
          className="App-button-cancel mt-10"
          onClick={cancelRec}
        >
          Cancelar
        </button>
      }
      { !listening && !fetching && !isBrazilianMode() && messages.length > 2 &&
        <button
          className="App-button-no-border mt-10"
          onClick={feedback}
          style={{ marginBottom: -10 }}
        >
          Receber Feedback
        </button>
      }
      { !listening &&
        <button
          className="App-button-no-border mt-10"
          onClick={exit}
        >
          Voltar
        </button>
      }
    </>
  );
}

export default Chat;
